import { LoanCalculatorUI } from "modules";

const LoanCalculator = () => {
  return (
    <>
      <LoanCalculatorUI />
    </>
  );
};

export { LoanCalculator };
